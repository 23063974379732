let scrollHandler = null;

$(document).on('turbolinks:before-cache', function() {
  if (scrollHandler) {
    window.removeEventListener('scroll', scrollHandler);
    scrollHandler = null;
  }
});

$(document).on('turbolinks:load', function(){
  if ($('body').hasClass("listings index") || $('body').hasClass("listings search")) {
    const container = document.getElementById('container');
    const sidebar = document.getElementById('sidebar');
    const offsetY = 20;
    container.style.minHeight = sidebar.clientHeight + 'px';

    scrollHandler = () => {
      const containerRect = container.getBoundingClientRect();
      const isReachBottom = (sidebar,containerRect,offsetY)=>{
        if( sidebar.clientHeight < window.innerHeight ){
          if(containerRect.bottom <= sidebar.clientHeight + offsetY){
            return true;
          }else{
            return false;
          }
        }else{
          if(containerRect.bottom < window.innerHeight - offsetY){
            return true;
          }else{
            return false;
          }
        }
      }

      if( isReachBottom(sidebar,containerRect,offsetY) ){
        sidebar.classList.add('is-end');
        sidebar.classList.add('is-fixed');
        sidebar.style.left = '';
      }else if( containerRect.top < offsetY ){
        sidebar.classList.add('is-fixed');
        sidebar.classList.remove('is-end');
        sidebar.style.left = containerRect.left + 'px';
      }else{
        sidebar.classList.remove('is-fixed');
        sidebar.classList.remove('is-end');
        sidebar.style.left = '';
      }
    };

    window.addEventListener('scroll', scrollHandler);
    // 初期状態の設定
    scrollHandler();
  }
});