$(document).on('turbolinks:load', function(){
  if ($('#reservation-request-btn')) {
    $('#reservation-request-btn').prop("disabled", true);
    $('#request-check').click(function(){
      if ($(this).prop('checked')){
        $('#reservation-request-btn').prop("disabled", false).removeClass("disabled");
      } else {
        $('#reservation-request-btn').prop("disabled", true).addClass("disabled");
      };
    });
  }
  if ($('#reservation-cancel-btn')) {
    $('#reservation-cancel-btn').click(function(){
      showLoading();
      let form = $(this).closest('#reservation-request-form');
      form.attr("action", $(this).data("url"));
      form.submit();
    });
  }
  if ($('#reservation-purchase-btn')) {
    $('#reservation-purchase-btn').prop("disabled", true);
    $('#purchase-check').click(function(){
      if ($(this).prop('checked')){
        $('#reservation-purchase-btn').prop("disabled", false).removeClass("disabled");
      } else {
        $('#reservation-purchase-btn').prop("disabled", true).addClass("disabled");
      };
    });
  }
  if ($('body').hasClass("reservations new")) {
    var submit_flg = false;
    window.addEventListener('beforeunload', function(e) {
      if( !submit_flg ) {
        e.preventDefault();
        e.returnValue = '他のページに移動しますか？';
      }
    });
    var form = document.getElementById('new_reservation');
    form.addEventListener('submit', function(e) {
      submit_flg = true;
    });
  }
  if ($('body').hasClass("host show host requested") || $('body').hasClass("guest show guest approval")){
    window.onbeforeunload = function(e) {
      e.returnValue = "ページを離れようとしています。よろしいですか？";
    }
  }
});