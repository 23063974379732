let swiperWorkInstance = null;

document.addEventListener('DOMContentLoaded', initWorkSwiper);
$(document).on('turbolinks:before-cache', cleanupWorkSwiper);
$(document).on('turbolinks:load', initWorkSwiper);

function cleanupWorkSwiper() {
  if (swiperWorkInstance) {
    swiperWorkInstance.destroy();
    swiperWorkInstance = null;
  }
}

function initWorkSwiper() {
  cleanupWorkSwiper();

  if (document.querySelector('.swiper-work')) {
    swiperWorkInstance = new Swiper(".swiper-work", {
      spaceBetween: 20,
      slidesPerView: 1,
      loop: true,
      breakpoints: {
        768: { slidesPerView: 6 }
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      observer: true,
      observeParents: true,
    });
  }
}