$(document).on('turbolinks:load', function(){
  $('#notification_email_form').on('cocoon:after-insert', function() {
    check_to_hide_or_show_add_link();
  });
  $('#notification_email_form').on('cocoon:after-remove', function() {
    check_to_hide_or_show_add_link();
  });
  check_to_hide_or_show_add_link();

  function check_to_hide_or_show_add_link() {
    if ($('#notification_email_form .nested-fields').length == 3) {
      $('#notification_email_form .links').hide();
    } else {
      $('#notification_email_form .links').show();
    }
  };
});