import React, { FC, useEffect, useRef, useState } from "react"
import consumer from "utils/channels/consumer"

type Message = {
  id: string
  sender_id: string
  body: string
  sent_at: string
  isMine: boolean
  images: string[]
  files: {
    url: string
    name: string
  }[]
}

type MessageFromServer = {
  message: {
    id: string
    sender_id: string
    body: string
    sent_at: string
  }
  files: string[]
  file_names: string[]
}

type User = {
  id: string
  name: string
  image: string
}

interface IProps {
  roomId: string
  members: {
    me: User
    you: User
  }
  messages: MessageFromServer[]
  defaultImagePath: string
}
const ChatThread: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<Message[]>(
    _convertMessages(props.messages, props.members.me.id)
  )
  const [body, setBody] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const onChangeTextarea: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    e.preventDefault()
    setBody(e.target.value)
  }

  const onClickSelectFileBtn = () => fileInputRef.current.click()
  const onSelectFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    if (e.target.files.length < 1) return
    const file = e.target.files[0]
    if (file.size > 1024 * 1024 * 8) {
      alert("8MB以内のファイルを選択してください。")
      return
    }
    // if (!file.name.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)){
    //   alert("アップロードするデータのファイル名は半角英数字のみにして下さい。")
    //   return
    // }

    setFile(file)
  }

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    if (!body.length) {
      alert("テキストを入力してください。")
      return
    }
    if (loading) return

    setLoading(true)

    const formData = new FormData()
    formData.append("chat_room[chat_messages_attributes][][body]", body)
    if (file)
      formData.append(
        "chat_room[chat_messages_attributes][][chat_message_files_attributes][][file]",
        file
      )

    await fetch(window.location.href, {
      method: "put",
      headers: {
        "X-CSRF-Token": document
          .getElementsByName("csrf-token")[0]
          .getAttribute("content"),
      },
      body: formData,
    })
    _clearState()
    setTimeout(() => setLoading(false), 1000)
  }

  const _clearState = () => {
    setBody("")
    setFile(null)
    fileInputRef.current.value = null
  }

  useEffect(() => {
    setMessages(_convertMessages(props.messages, props.members.me.id))
  }, [props.messages])

  useEffect(() => {
    consumer.subscriptions.create(
      { channel: "ChatChannel", room_id: props.roomId },
      {
        received: (data: MessageFromServer) => {
          setTimeout(() => {
            setLoading(false)
            setMessages((prev) => [
              ...prev,
              _convertMessage(data, props.members.me.id),
            ])
          }, 1000)
        },
      }
    )
  }, [])

  const lastSentAt = messages.length
    ? new Date(messages[messages.length - 1].sent_at)
    : null
  const lastSentAtStr = lastSentAt
    ? `${lastSentAt.getFullYear()}.${lastSentAt.getMonth() + 1}.${lastSentAt.getDate()} ${lastSentAt.getHours()}:${lastSentAt.getMinutes().toString().padStart(2, "0")}`
    : ""

  return (
    <div className="mb-5">
      <div className="border-b chat-bottom-container pb-2">
        <div className="d-flex align-items-center">
          <img
            className="mr-3 cover-image h20 w20"
            src={props.members.you.image ?? props.defaultImagePath}
          />
          <p>{props.members.you.name}さんとメッセージ</p>
        </div>
        {lastSentAt && <p className="last-message">最新メッセージ {lastSentAtStr}</p>}
      </div>
      <div>
        {messages.map((message) => {
          const sentAt = new Date(message.sent_at)
          const sentAtStr = `${sentAt.getFullYear()}.${sentAt.getMonth() + 1}.${sentAt.getDate()} ${sentAt.getHours()}:${sentAt.getMinutes().toString().padStart(2, "0")}`
          return (
            <div
              className="d-flex mt-5"
              key={message.id}
              style={{
                display: "flex",
                justifyContent: message.isMine ? "flex-end" : "flex-start",
              }}
            >
              {message.isMine ? (
                <>
                  <div className="chat-container">
                    <div className="chat-box-sell">
                      <p className="chat-body">{message.body}</p>
                      <div className="d-flex align-items-center justify-content-between">
                        <>
                          {message.files.map((file) => (
                            <div key={file.url}>
                              <p className="mt-4 mb-4">【添付されたファイル】</p>
                              <a href={file.url} target="_blank">
                                <p className="file-text">
                                  {file.name}
                                </p>
                              </a>
                            </div>
                          ))}
                          {message.images.map((image) => (
                            <div key={image}>
                              <p className="mt-3 mb-3">【添付されたファイル】</p>
                              <a href={image} target="_blank">
                                <img className="chat-img" src={image} />
                              </a>
                            </div>
                          ))}
                        </>
                        <div className="add-text">
                          {message.images.map((image) => (
                            <div key={image}>
                              <a href={image} target="_blank">
                                <p>添付された画像を見る</p>
                                <p>(別タブで開きます)</p>
                              </a>
                            </div>
                          ))}
                          {message.files.map((file) => (
                            <div key={file.url}>
                              <a href={file.url} target="_blank">
                                {file.name.endsWith("pdf")
                                  ? (
                                    <>
                                      <p>添付されたPDFを見る</p>
                                      <p>(別タブで開きます)</p>
                                    </>
                                  )
                                  : <p>添付されたファイルをダウンロードする</p>
                                }
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <p>{sentAtStr}</p>
                  </div>
                  <div className="triangle-sell" />
                  <img className="ml-3 cover-image h20 w20" src={props.members.me.image ?? props.defaultImagePath} />
                </>
              ) : (
                <>
                  <img className="mr-3 cover-image h20 w20" src={props.members.you.image ?? props.defaultImagePath} />
                  <div className="triangle-buy" />
                  <div className="chat-container">
                    <div className="chat-box-buy">
                      <p className="chat-body">{message.body}</p>
                      <div className="d-flex align-items-center justify-content-between">
                        <>
                          {message.files.map((file) => (
                            <div key={file.url}>
                              <p className="mt-3 mb-3">【添付されたファイル】</p>
                              <a href={file.url} target="_blank">
                                <p className="file-text">
                                  {file.name}
                                </p>
                              </a>
                            </div>
                          ))}
                          {message.images.map((image) => (
                            <div key={image}>
                              <p className="mt-3 mb-3">【添付されたファイル】</p>
                              <a href={image} target="_blank">
                                <img className="chat-img" src={image} />
                              </a>
                            </div>
                          ))}
                        </>
                        <div className="add-text">
                          {message.images.map((image) => (
                            <div key={image}>
                              <a href={image} target="_blank">
                                <p>添付された画像を見る</p>
                                <p>(別タブで開きます)</p>
                              </a>
                            </div>
                          ))}
                          {message.files.map((file) => (
                            <div key={file.url}>
                              <a href={file.url} target="_blank">
                                {file.name.endsWith("pdf")
                                  ? (
                                    <>
                                      <p>添付されたPDFを見る</p>
                                      <p>(別タブで開きます)</p>
                                    </>
                                  )
                                  : <p>添付されたファイルをダウンロードする</p>
                                }
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <p>{sentAtStr}</p>
                  </div>
                </>
              )}
            </div>
          )
        })}
        <textarea
          className="message-input"
          value={body}
          onChange={onChangeTextarea}
          placeholder="メッセージを入力してください。"
        />
        <div className="message-bottom">
          <div className="file-button">
            <div>
              <button onClick={onClickSelectFileBtn} className="d-flex align-items-center chat-file-choice" >
                <i className="fas fa-paperclip mr-2" />
                <p>ファイルを選択</p>
              </button>
              <input
                hidden
                className=""
                ref={fileInputRef}
                type="file"
                onChange={onSelectFile}
              />
            </div>
            <p className="file-text">{file?.name}</p>
          </div>
          <button
            onClick={onSubmit}
            className="btn btn-green send-button"
            disabled={loading}
          >
            {loading ? '送信中...' : '送信'}
          </button>
        </div>
      </div>
      <p>※8MB以内のファイルを選択してください。</p>
      {/* <p>※アップロードするデータのファイル名は半角英数字のみにして下さい。</p> */}
    </div>
  )
}

export default ChatThread

const _convertMessages: (
  messages: MessageFromServer[],
  myId: string
) => Message[] = (messages, myId) =>
    messages.map((msgObj) => _convertMessage(msgObj, myId))

const _convertMessage: (msgObj: MessageFromServer, myId: string) => Message = (
  msgObj,
  myId
) => {
  const images = []
  const files = []
  msgObj.files.forEach((url, i) => {
    const splitted = url.split('.')
    const suffix = splitted[splitted.length - 1]
    if (
      [
        'jpeg',
        'Jpeg',
        'JPEG',
        'jpg',
        'Jpg',
        'JPG',
        'png',
        'Png',
        'PNG',
        'gif',
        'Gif',
        'GIF',
      ].includes(suffix)
    ) {
      images.push(url)
    } else {
      files.push({
        url: url,
        name: msgObj.file_names[i],
      })
    }
  })
  return {
    ...msgObj.message,
    isMine: msgObj.message.sender_id === myId,
    images,
    files,
  }
}
