$(document).on('turbolinks:load', function(){
  if ($('body').hasClass("listings new") || $('body').hasClass("listings edit")) {
    if ($('#listing_year_unknown').prop('checked')) {
      $('#listing_model_year').val("");
      $('#listing_model_year').prop("disabled", true).addClass("disabled");
    } else {
      $('#listing_model_year').prop("disabled", false).removeClass("disabled");
    }
    $('#listing_year_unknown').change(function () {
      if ($('#listing_year_unknown').prop('checked')) {
        $('#listing_model_year').val("");
        $('#listing_model_year').prop("disabled", true).addClass("disabled");
      } else {
        $('#listing_model_year').prop("disabled", false).removeClass("disabled");
      }
    });
    if ($('#listing_contact_flg').prop('checked')) {
      $('#CalcPriceFrom').css('display', 'none');
      $('#listing_price').val("");
      $('#listing_price_commission').text("");
      $('#listing_price_profit').text("");
      $('#listing_price_price_on_tax').text("");
      $('#listing_price_price_in_tax').text("");
      $('#listing_price_commission_on_tax').text("");
      $('#listing_price_profit_on_tax').text("");
      $('#listing_price').prop("disabled", true).addClass("disabled");
    } else {
      $('#CalcPriceFrom').css('display', 'block');
      $('#listing_price').prop("disabled", false).removeClass("disabled");
    }
    $('#listing_contact_flg').change(function () {
      if ($('#listing_contact_flg').prop('checked')) {
        $('#CalcPriceFrom').css('display', 'none');
        $('#listing_price').val("");
        $('#listing_price_commission').text("");
        $('#listing_price_profit').text("");
        $('#listing_price_price_on_tax').text("");
        $('#listing_price_commission_on_tax').text("");
        $('#listing_price_commission_on_tax').text("");
        $('#listing_price_profit_on_tax').text("");
        $('#listing_price').prop("disabled", true).addClass("disabled");
      } else {
        $('#CalcPriceFrom').css('display', 'block');
        $('#listing_price').prop("disabled", false).removeClass("disabled");
      }
    });
    $(function () {
      $('.count_up1').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up1-text').text(len)
      })
      $('.count_up2').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up2-text').text(len)
      })
      $('.count_up3').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up3-text').text(len)
      })
      $('.count_up4').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up4-text').text(len)
      })
      $('.count_up5').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up5-text').text(len)
      })
      $('.count_up6').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up6-text').text(len)
      })

    })
    $(function () {
      //1
      var elements1 = document.getElementsByClassName('count_up1')
      const value1 = elements1[0].value
      var elements1_text = document.getElementsByClassName('count_up1-text')
      elements1_text[0].innerHTML = value1.length
      //2
      var elements2 = document.getElementsByClassName('count_up2')
      const value2 = elements2[0].value
      var elements2_text = document.getElementsByClassName('count_up2-text')
      elements2_text[0].innerHTML = value2.length
      //3
      // var elements3 = document.getElementsByClassName('count_up3')
      // const value3 = elements3[0].value
      // var elements3_text = document.getElementsByClassName('count_up3-text')
      // elements3_text[0].innerHTML = value3.length
      //4
      var elements4 = document.getElementsByClassName('count_up4')
      const value4 = elements4[0].value
      var elements4_text = document.getElementsByClassName('count_up4-text')
      elements4_text[0].innerHTML = value4.length
      //5
      var elements5 = document.getElementsByClassName('count_up5')
      const value5 = elements5[0].value
      var elements5_text = document.getElementsByClassName('count_up5-text')
      elements5_text[0].innerHTML = value5.length
    })
    function textLength(text) {
      var regexp = /[\x01-\x7E\u{FF65}-\u{FF9F}]/mu
      var len = 0
      for (i = 0; i < text.length; i++) {
        var ch = text[i]
        len += regexp.test(new String(ch)) ? 1 : 1
      }
      return len
    }

    var submit_flg = false;
    window.addEventListener('beforeunload', function(e) {
      if( !submit_flg ) {
        e.preventDefault();
        e.returnValue = '他のページに移動しますか？';
      }
    });
    var form = document.getElementById('listing_form');
    form.addEventListener('submit', function(e) {
      submit_flg = true;
    });
  }

  if ($('body').hasClass("listings file_upload")) {
    window.onbeforeunload = function(e) {
      e.returnValue = "ページを離れようとしています。よろしいですか？";
    }
  }
  if ($('body').hasClass("listings preview")) {
    $('.listing-button').addClass("disabled");
    $("[name='check_agree']").click(function() {
      var result = $("[name='check_agree']:checked").val();
      if(result){
        $('.listing-button').removeClass("disabled");
      } else {
        $('.listing-button').addClass("disabled");
      }
    });
    window.onbeforeunload = function(e) {
      e.returnValue = "ページを離れようとしています。よろしいですか？";
    }
  }
});