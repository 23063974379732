$(document).on('turbolinks:load', function(){
  lightbox.option({
    resizeDuration: 200,
    wrapAround: true,
  })
  const break_point = 768
  function gallery_img_resize() {
    let $window = $(window)
    let $lightbox_img = $('.zoom a img')
    let default_height = 230
    if ($window.width() <= break_point) {
      $lightbox_img.css({
        height: `${$lightbox_img.width()}px`,
      })
    } else {
      $lightbox_img.css({
        height: `${default_height}px`,
      })
    }
  }
  $(window).on('load resize', function () {
    gallery_img_resize()
  })
})