import React, { useState } from "react"
import ImageUploading, { ImageListType } from "react-images-uploading"
import { Oval } from "react-loader-spinner"

interface IProps {
  object_type: string
  id: string
  images: any[]
  image_urls: string[]
}
const ImageUpload: React.FC<IProps> = props => {
  const [uploading, setUploading] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const [imageObjects, setImageObjects] = React.useState<any[]>(props.images ?? [])
  const [images, setImages] = React.useState<ImageListType | string[]>(props.image_urls ?? [])
  const maxNumber = 20

  const onChangeImageUploading = async (imageList: ImageListType, addUpdateIndex?: number[]) => {
    if (uploading || deleting) return

    setUploading(true)

    const newImageArr = Array.from(imageList).filter((_f, i) => addUpdateIndex.includes(i))
    const formData = new FormData()
    newImageArr.forEach(f =>
      formData.append(`listing[listing_images_attributes][][image]`, f.file)
    )
    try {
      const result = await fetch(`${window.location.origin}/${props.object_type}/${props.id}/upload_image`, {
        method: "put",
        headers: { "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content") },
        body: formData,
      })
      const reader = result.body.getReader()
      const decoder = new TextDecoder()
      let resultText = ""
      let flag = false
      while (!flag) {
        const data = await reader.read()
        flag = data.done
        resultText += decoder.decode(data.value)
      }
      const resultObj = JSON.parse(resultText)
      setImages(resultObj.map(o => o.image.url))
      setImageObjects(resultObj)
    } catch (e) {
      console.log(e)
      // if (e.message.includes("Unexpected token < in JSON at position 0")){
      //   alert("アップロードするデータのファイル名は半角英数字のみにして下さい。")
      //   return
      // }
    } finally {
      setUploading(false)
    }
  }
  const onErrorImageUploading = (
    errors: {
      maxFileSize?: boolean;
      maxNumber?: boolean;
      acceptType?: boolean;
      resolution?: boolean;
    },
    _files: ImageListType,
  ) => {
    if (errors.acceptType) alert("PNG・JPG・GIF形式の写真を登録してください。")
    if (errors.maxFileSize) alert("8MB以内の写真を登録してください。")
    if (errors.maxNumber) alert("登録できる写真は最大２０枚です。")
  }

  const onClickDelete = async (index: number) => {
    if (uploading || deleting) return

    if (!confirm("削除しますか？"))
      return

    if (images.length === 1 && !confirm("画像登録が一枚もない場合は商品状態が編集中に変更されます。"))
      return

    setDeleting(true)

    const formData = new FormData()
    formData.append(`listing[listing_images_attributes][][id]`, imageObjects[index].id)
    formData.append(`listing[listing_images_attributes][][_destroy]`, "true")
    try {
      await fetch(`${window.location.origin}/${props.object_type}/${props.id}/upload_image`, {
        method: "put",
        headers: { "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content") },
        body: formData,
      })
      setImages(prev => (prev as any[]).filter((_, i) => i !== index))
      setImageObjects(prev => (prev as any[]).filter((_, i) => i !== index))
    } catch (e) {
      console.log(e)
      // if (e.message.includes("Unexpected token < in JSON at position 0")){
      //   alert("アップロードするデータのファイル名は半角英数字のみにして下さい。")
      //   return
      // }
    } finally {
      setDeleting(false)
    }
  }

  return (
    <>
      <div className="image-c">
        <ImageUploading
          value={images as any}
          onChange={onChangeImageUploading}
          maxNumber={maxNumber}
          acceptType={["jpg", "gif", "png", "jpeg"]}
          maxFileSize={1024 * 1024 * 8}
          onError={onErrorImageUploading}
          multiple
        >
          {({ imageList, onImageUpload, isDragging, dragProps }) => (
            <div className="upload__image-wrapper">
              <div className="sub-container">
                {imageList
                  .sort((a, b) => {
                    const idA = parseInt(a.split('/').slice(-2, -1)[0], 10);
                    const idB = parseInt(b.split('/').slice(-2, -1)[0], 10);
                    return idA - idB;
                  }).map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image.dataURL ?? image as unknown as string} alt="" width="100" />
                      <span onClick={() => onClickDelete(index)}>
                        <i className="fas fa-times" />
                      </span>
                    </div>
                  ))
                }
                <div className="image-item">
                  <div className="remaining">
                    <div className="remaining-text">
                      <p className="text-center">残り</p>
                      <div className="text-center">
                        <p className="d-inline">
                          {maxNumber - imageList.length <= 0
                            ? 0
                            : maxNumber - imageList.length}
                        </p>
                        <p className="d-inline">枚</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="upload_container"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <div className="bracket-container">
                  <div className="d-flex justify-content-between">
                    <div className="bracket_lt" />
                    <div className="bracket_rt" />
                  </div>
                  <div className={`image-a ${maxNumber - imageList.length <= 0 ? "text-hidden" : ""}`}>
                    <div className="w28 mx-auto">
                      <i className="far fa-image fa-5x"></i>
                    </div>
                    <p>ドラック＆ドロップまたはクリックして写真をアップロード</p>
                    <p>最大{maxNumber}枚までアップロードできます</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="bracket_lb" />
                    <div className="bracket_rb" />
                  </div>
                </div>
              </div>
              <div className="sub-container">
                <p>※8MB以内のpng・jpg・jpeg・gif形式の写真を登録してください。</p>
                {/* <p>※アップロードするデータのファイル名は半角英数字のみにして下さい。</p> */}
              </div>
            </div>
          )}
        </ImageUploading>
      </div>

      {(uploading || deleting) &&
        <div className="image_upload_loading">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            color="#00aa6e"
            secondaryColor="white"
          />
          <p>{
            uploading
              ? "アップロード中..."
              : deleting
                ? "削除中..."
                : ""
          }</p>
        </div>
      }
    </>
  )
}

export default ImageUpload
