var gmoCardTokenHandler;

$(document).on('turbolinks:load', function(){
  var creditCardForm, gmoShopId, submitBtn;
  if ($('#modal-confirm-card-remove').length) {
    $('.js-modal-confirm-card-remove').on('click', function(e) {
      $('#modal-confirm-card-remove').modal();
      return e.preventDefault();
    });
  }
  creditCardForm = $('#edit_gmo_credit_card');
  if (creditCardForm.length > 0) {
    gmoShopId = document.querySelector('meta[name=\'gmo_pg_shop_id\']').content;
    submitBtn = document.getElementById('credit_card_btn');
    creditCardForm.on('submit', function(event) {
      var card_no, expire, expire_mm, expire_yy, gmoData, holdername, security_code;
      event.preventDefault();
      submitBtn.setAttribute("disabled", "disabled");
      card_no = $('#gmo_credit_card_card_no').val();
      security_code = $('#gmo_credit_card_security_code').val();
      expire_mm = $('#gmo_credit_card_expire_mm').val();
      expire_yy = $('#gmo_credit_card_expire_yy').val();
      holdername = $('#gmo_credit_card_holder_name').val();
      expire = expire_yy + expire_mm;
      gmoData = {
        cardno: card_no,
        expire: expire,
        securitycode: security_code,
        holdername: holdername
      };
      Multipayment.init(gmoShopId);
      return Multipayment.getToken(gmoData, function(res) {
        var tokenError = {
          "000": "トークン取得正常終了",
          "100": "カード番号は必須項目です。",
          "101": "カード番号のフォーマットが正しくありません。",
          "102": "カード番号のフォーマットが正しくありません。",
          "110": "有効期限は必須項目です。",
          "111": "有効期限のフォーマットが正しくありません。",
          "112": "有効期限のフォーマットが正しくありません。",
          "113": "有効期限のフォーマットが正しくありません。",
          "121": "セキュリティコードのフォーマットが正しくありません。",
          "122": "セキュリティコードのフォーマットが正しくありません。",
          "131": "名義人のフォーマットが正しくありません。",
          "132": "名義人のフォーマットが正しくありません。",
          "141": "エラー発生しました。E141",
          "142": "エラー発生しました。E142",
          "150": "エラー発生しました。E150",
          "160": "エラー発生しました。E160",
          "161": "エラー発生しました。E161",
          "162": "エラー発生しました。E162",
          "170": "エラー発生しました。E170",
          "180": "エラー発生しました。E180",
          "190": "エラー発生しました。E190",
          "191": "エラー発生しました。E191",
          "501": "エラー発生しました。E501",
          "502": "エラー発生しました。E502",
          "511": "エラー発生しました。E511",
          "512": "エラー発生しました。E512",
          "521": "エラー発生しました。E521",
          "522": "エラー発生しました。E522",
          "531": "エラー発生しました。E531",
          "541": "エラー発生しました。E541",
          "551": "エラー発生しました。E551",
          "552": "エラー発生しました。E552",
          "901": "エラー発生しました。E901",
          "902": "エラー発生しました。E902"
        };
        if (res.resultCode == "000") {
          gmoCardTokenHandler(res.tokenObject.token, res.tokenObject.maskedCardNo, creditCardForm);
        } else if (res.resultCode in tokenError) {
          alert(tokenError[res.resultCode]);
          location.reload();
        } else {
          alert("エラー発生しました。");
          location.reload();
        }
      });
    });
    return false;
  }
});

gmoCardTokenHandler = function(token, maskedCardNo, form) {
  var maskedCardNoHiddenInput, tokenHiddenInput;
  tokenHiddenInput = document.createElement('input');
  tokenHiddenInput.setAttribute('type', 'hidden');
  tokenHiddenInput.setAttribute('name', 'gmo_credit_card[gmo_token]');
  tokenHiddenInput.setAttribute('value', token);
  maskedCardNoHiddenInput = document.createElement('input');
  maskedCardNoHiddenInput.setAttribute('type', 'hidden');
  maskedCardNoHiddenInput.setAttribute('name', 'gmo_credit_card[card_no]');
  maskedCardNoHiddenInput.setAttribute('value', maskedCardNo);
  $('#gmo_credit_card_card_no').remove();
  $('#gmo_credit_card_security_code').remove();
  $('#gmo_credit_card_expire_mm').remove();
  $('#gmo_credit_card_expire_yy').remove();
  $('#gmo_credit_card_holder_name').remove();
  form.append(tokenHiddenInput);
  form.append(maskedCardNoHiddenInput);
  form.unbind().submit();
};