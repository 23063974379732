let loading = null;

// アニメーションのスタイルを追加
const addSpinnerStyle = () => {
  const style = document.createElement('style');
  style.textContent = `
    @keyframes spinner-rotate {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(style);
};

// 初期化時にアニメーションスタイルを追加
addSpinnerStyle();

// グローバルスコープで関数を定義
window.showLoading = () => {
  if (loading) {
    loading.style.display = 'flex';
    loading.classList.remove('d-none');
  } else {
    console.warn('Loading element is null');
  }
};

window.hideLoading = () => {
  if (loading) {
    loading.classList.add('d-none');
    loading.style.display = 'none';
  } else {
    console.warn('Loading element is null');
  }
};

// Turbolinksのイベント
document.addEventListener('turbolinks:load', () => {
  loading = document.getElementById('loading');

  if (loading) {
    // スタイルを直接適用
    loading.style.cssText = `
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: 100vw !important;
      height: 100vh !important;
      background: rgba(0, 0, 0, 0.5) !important;
      z-index: 2147483647 !important;
      display: none !important;
      justify-content: center !important;
      align-items: center !important;
      pointer-events: all !important;
    `;

    // スピナーのスタイルも適用
    const spinner = loading.querySelector('.spinner-border');
    if (spinner) {
      spinner.style.cssText = `
        width: 8rem !important;
        height: 8rem !important;
        border: 0.6em solid rgba(255, 255, 255, 0.5) !important;
        border-right-color: #fff !important;
        border-radius: 50% !important;
        animation: spinner-rotate 0.85s linear infinite !important;
      `;
    }
  }
});

document.addEventListener('turbolinks:click', () => {
  showLoading();
});

document.addEventListener('turbolinks:before-visit', () => {
  showLoading();
});

document.addEventListener('turbolinks:visit', () => {
  showLoading();
});

document.addEventListener('turbolinks:request-start', () => {
  showLoading();
});

document.addEventListener('turbolinks:request-end', () => {
  hideLoading();
});

document.addEventListener('turbolinks:render', () => {
  hideLoading();
});

// Ajaxのイベント
document.addEventListener('ajax:send', () => {
  showLoading();
});

document.addEventListener('ajax:complete', () => {
  hideLoading();
});

// フォーム送信時
document.addEventListener('submit', (e) => {
  const form = e.target;
  const submitButton = form.querySelector('input[type="submit"], button[type="submit"]');
  if (submitButton) {
    submitButton.disabled = true;
    showLoading();
  }
});