$(document).on('turbolinks:load', function(){
  if ($('body').hasClass("bank_accounts")) {
    $(document).ready(function() {
      $('.select2_search').select2({
        width: 'resolve',
        selectOnClose: true,
        matcher: function(params, data) {
          return matchStart(params, data);
        },
      });
      function matchStart(params, data) {
        params.term = params.term || '';
        if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
          return data;
        }
        return false;
      }
    });
  }
});