$(document).on('turbolinks:load', function(){
  $('#shipping_info_form').on('cocoon:after-insert', function() {
    check_to_hide_or_show_add_link();
  });
  $('#shipping_info_form').on('cocoon:after-remove', function() {
    check_to_hide_or_show_add_link();
  });
  check_to_hide_or_show_add_link();

  $('#user_shipping_infos_attributes_0_postcode').jpostal({
    postcode: ['#user_shipping_infos_attributes_0_postcode'],
    address: {
      '#user_shipping_infos_attributes_0_prefecture_code': '%3',
      '#user_shipping_infos_attributes_0_address_city': '%4',
      '#user_shipping_infos_attributes_0_address_street': '%5%6%7',
    },
  });

  $('#user_shipping_infos_attributes_1_postcode').jpostal({
    postcode: ['#user_shipping_infos_attributes_1_postcode'],
    address: {
      '#user_shipping_infos_attributes_1_prefecture_code': '%3',
      '#user_shipping_infos_attributes_1_address_city': '%4',
      '#user_shipping_infos_attributes_1_address_street': '%5%6%7',
    },
  });

  function check_to_hide_or_show_add_link() {
    if ($('#shipping_info_form .nested-fields').length == 2) {
      $('#shipping_info_form .links').hide();
    } else {
      $('#shipping_info_form .links').show();
    }
  };
});