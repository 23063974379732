let swiperNewInstance = null;

document.addEventListener('DOMContentLoaded', initNewSwiper);
$(document).on('turbolinks:before-cache', cleanupNewSwiper);
$(document).on('turbolinks:load', initNewSwiper);

function cleanupNewSwiper() {
  if (swiperNewInstance) {
    swiperNewInstance.destroy();
    swiperNewInstance = null;
  }
}

function initNewSwiper() {
  cleanupNewSwiper();

  if (document.querySelector('.swiper-new')) {
    swiperNewInstance = new Swiper(".swiper-new", {
      spaceBetween: 20,
      slidesPerView: 1,
      loop: true,
      breakpoints: {
        768: { slidesPerView: 6 }
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      observer: true,
      observeParents: true,
      navigation: { nextEl: ".swiper-button-next-new", prevEl: ".swiper-button-prev-new" },
      slideNextClass: ".swiper-button-next-new",
      slidePrevClass: ".swiper-button-prev-new",
    });
  }
}
