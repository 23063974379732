$(document).on('turbolinks:load', function(){
  var path = location.pathname
  //↓ナビゲーションのリスト要素を取得
  var list = $('ul.sidebar-content li')
  //↓全てのナビゲーションに対してこれを実行
  list.each(function () {
    //ナビゲーションの中の<a>のhrefの値を取得
    var link = $(this).find('a').attr('href')
    //pathの中にhrefの値が含まれていればclassを付与
    if (path.indexOf(link) > -1) {
      $(this).addClass('now_here')
    }
  })
  //遷移時にアコーディオンを開く
  var child = $('ul.sidebar-content li.triangle.child')
  if (child.hasClass('now_here')) {
    $('.collapse').collapse('show')
  }
})