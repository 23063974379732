$(document).on('turbolinks:load', function(){
  if ($('#profile_invoice_number_unknown').prop('checked')) {
    $('#profile_invoice_number').val("");
    $('#profile_invoice_number').prop("disabled", true).addClass("disabled");
  } else {
    $('#profile_invoice_number').prop("disabled", false).removeClass("disabled");
  }
  $('#profile_invoice_number_unknown').change(function () {
    if ($('#profile_invoice_number_unknown').prop('checked')) {
      $('#profile_invoice_number').val("");
      $('#profile_invoice_number').prop("disabled", true).addClass("disabled");
    } else {
      $('#profile_invoice_number').prop("disabled", false).removeClass("disabled");
    }
  });

  $('#profile_postcode').jpostal({
    postcode: ['#profile_postcode'],
    address: {
      '#profile_prefecture_code': '%3',
      '#profile_address_city': '%4',
      '#profile_address_street': '%5%6%7',
    },
  });

  if ($('body').hasClass("profiles new") || $('body').hasClass("profiles create")) {
    $('.profile-button').prop("disabled", true);
    $('.trigger-another-company-block').css("display", "none");
    $('.trigger-others-block').css("display", "none");
    $('.purpose-others-block').css("display", "none");

    $("[name='check_agree']").click(function() {
      var result = $("[name='check_agree']:checked").val();
      var trigger_value = $('[name="profile[questionnaire_attributes][trigger]"]:checked').val();
      var purpose_value = $('[name="profile[questionnaire_attributes][purpose][]"]:checked').val();
      if(result && trigger_value !== undefined && purpose_value !== undefined){
        $('.profile-button').prop("disabled", false);
      } else {
        $('.profile-button').prop("disabled", true);
      }
    });

    $('[name="profile[questionnaire_attributes][trigger]"]').click(function() {
      var result = $("[name='check_agree']:checked").val();
      var trigger_value = $('[name="profile[questionnaire_attributes][trigger]"]:checked').val();
      var purpose_value = $('[name="profile[questionnaire_attributes][purpose][]"]:checked').val();
      if(result && trigger_value !== undefined && purpose_value !== undefined){
        $('.profile-button').prop("disabled", false);
      } else {
        $('.profile-button').prop("disabled", true);
      }
    });

    $('[name="profile[questionnaire_attributes][purpose][]"').click(function() {
      var result = $("[name='check_agree']:checked").val();
      var trigger_value = $('[name="profile[questionnaire_attributes][trigger]"]:checked').val();
      var purpose_value = $('[name="profile[questionnaire_attributes][purpose][]"]:checked').val();
      if(result && trigger_value !== undefined && purpose_value !== undefined){
        $('.profile-button').prop("disabled", false);
      } else {
        $('.profile-button').prop("disabled", true);
      }
    });

    $('[name="profile[questionnaire_attributes][trigger]"]').click(function(){
      var checked_value = $('[name="profile[questionnaire_attributes][trigger]"]:checked').val();
      if (checked_value == "another_company"){
        $('.trigger-another-company-block').css("display", "block");
        $('.trigger-others-block').css("display", "none");
        $('#profile_questionnaire_attributes_referral_company_name').attr('required', true);
      } else if (checked_value == "others") {
        $('.trigger-others-block').css("display", "block");
        $('.trigger-another-company-block').css("display", "none");
        $('#profile_questionnaire_attributes_referral_company_name').attr('required', false);
      } else {
        $('.trigger-another-company-block').css("display", "none");
        $('.trigger-others-block').css("display", "none");
        $('#profile_questionnaire_attributes_referral_company_name').attr('required', false);
      }
    });

    $('[name="profile[questionnaire_attributes][purpose][]"]').change(function(){
      if ($('#profile_questionnaire_attributes_purpose_その他').prop('checked')) {
        $('.purpose-others-block').css("display", "block");
      } else {
        $('.purpose-others-block').css("display", "none");
      }
    });
  }

  $('.modal a')
    .not('.dropdown-toggle')
    .on('click', function () {
      $('.modal').modal('hide')
  })
});