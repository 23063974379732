let swiperListingInstance = null;
let swiperListing2Instance = null;

$(document).on('turbolinks:before-cache', cleanupListingSwiper);
$(document).on('turbolinks:load', initListingSwiper);

function cleanupListingSwiper() {
  if (swiperListingInstance) {
    swiperListingInstance.destroy();
    swiperListingInstance = null;
  }
  if (swiperListing2Instance) {
    swiperListing2Instance.destroy();
    swiperListing2Instance = null;
  }
}

function initListingSwiper() {
  cleanupListingSwiper();

  if (document.querySelector('.mySwiper')) {
    swiperListingInstance = new Swiper('.mySwiper', {
      spaceBetween: 10,
      slidesPerView: 2,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 5,
        },
      },
    });

    swiperListing2Instance = new Swiper('.mySwiper2', {
      loop: true,
      thumbs: {
        swiper: swiperListingInstance,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
} 